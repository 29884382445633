<template>
  <div class="login-douglas">
    <div class="login-header">
      <div class="login-title">{{ $t("login_welcome") }}</div>
      <div class="close" @click="handleClose" />
    </div>
    <div class="login-body">
      <form autocomplete="on" class="middle left" @submit.prevent="handleSubmit">
        <div class="title">{{ $t('login_already_douglas') }}</div>
        <basic-input
          id="email"
          v-model="values.username"
          required
          type="email"
          :error="errors.username"
          autocomplete="email"
          :placeholder="$t('login_username')"
          @blur="validateLogin('username')"
        />
        <basic-input
          id="password"
          v-model="values.password"
          :error="errors.password"
          autocomplete="current-password"
          required
          :placeholder="$t('login_password')"
          @blur="validateLogin('password')"
        />
        <p class="input-footer forgot-password">{{ $t('login_password_forgot') }}</p>
        <basic-checkbox v-model="values.stayLogged" :label="$t('login_stay_logged')" />
        <button type="submit" class="button btn--secondary">{{ $t('login_log_in_button') }}</button>
      </form>
      <form class="middle right" autocomplete="on" @submit.prevent="handleSignUpSubmit">
        <basic-input
          v-model="values.firstName"
          :error="errors.firstName"
          required
          autocomplete="given-name"
          :placeholder="$t('login_firstName')"
          @blur="validateSignUp('firstName')"
        />
        <basic-input
          v-model="values.surname"
          :error="errors.surname"
          required
          autocomplete="family-name"
          :placeholder="$t('login_surname')"
          @blur="validateSignUp('surname')"
        />
        <basic-input
          v-model="values.birth"
          :error="errors.birth"
          required
          autocomplete="billing bday"
          :placeholder="$t('login_birth')"
          @blur="validateSignUp('birth')"
        />
        <p class="input-footer">{{ $t('login_password_forgot') }}</p>
        <div class="gender-input">
          <div>{{ $t('login_choose_gender') }}</div>
          <basic-radio-button
            v-model="values.gender"
            value="M"
            name="gender"
            :label="$t('login_male')"
          />
          <basic-radio-button
            v-model="values.gender"
            value="F"
            name="gender"
            :label="$t('login_female')"
          />
        </div>
        <basic-input
          v-model="values.email"
          :error="errors.email"
          required
          autocomplete="email"
          :placeholder="$t('login_email')"
          @blur="validateSignUp('email')"
        />
        <basic-input
          v-model="values.passwordSignup"
          :error="errors.passwordSignup"
          required
          :placeholder="$t('login_password')"
          @blur="validateSignUp('passwordSignup')"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as yup from 'yup'
import BasicCheckbox from '../components/basic/BasicCheckbox.vue'
import BasicInput from '../components/basic/BasicInput.vue'
import BasicRadioButton from '../components/basic/BasicRadioButton.vue'
import { ROUTER_NAMES } from '../settings'

export default {
  components: { BasicInput, BasicCheckbox, BasicRadioButton },
  data() {
    return {
      values: {
        username: '',
        password: '',
        stayLogged: false,
        firstName: '',
        surname: '',
        birth: '',
        email: '',
        passwordSignup: '',
      },
      errors: {
        username: '',
        password: '',
        firstName: '',
        surname: '',
        birth: '',
        email: '',
        passwordSignup: '',
      },
    }
  },
  computed: {
    loginSchema() {
      return yup.object().shape({
        username: yup
          .string()
          .email(this.$t('login_username_not_email'))
          .required(this.$t('login_username_required')),
        password: yup.string().required(this.$t('login_password_required')),
      })
    },
    signUpSchema() {
      return yup.object().shape({
        firstName: yup.string().required(this.$t('login_firstName_required')),
        surname: yup.string().required(this.$t('login_surname_required')),
        birth: yup
          .date(this.$t('login_birth_incorrect_format'))
          .required(this.$t('login_birth_required')),
        email: yup
          .string()
          .email(this.$t('login_username_not_email'))
          .required(this.$t('login_email_required')),
        passwordSignup: yup.string().required(this.$t('login_passwordSignup_required')),
      })
    },
  },
  methods: {
    ...mapActions({ login: 'profiler/login' }),
    validateLogin(field) {
      console.log(field)
      this.loginSchema
        .validateAt(field, this.login)
        .then(() => {
          this.errors[field] = ''
          console.log(field, 'OK')
        })
        .catch(err => {
          this.errors[field] = err.message
          console.warn(field, err)
        })
    },
    validateSignUp(field) {
      this.signUpSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = ''
        })
        .catch(err => {
          this.errors[field] = err.message
        })
    },
    handleClose() {
      if (this.$route.matched.length > 0) this.$router.back()
      else this.$routerPush({ name: ROUTER_NAMES.WELCOME })
    },
    handleSubmit() {
      const { username, password } = this.values
      console.log(this.values)
      this.login({ user: username, password }).then(() => {
        this._executeCallback()
      })
    },
    handleSignUpSubmit() {
      this._executeCallback()
    },
    _executeCallback() {
      const callbackPath = this.$route.query.callback
      if (callbackPath) {
        this.$router.push(callbackPath)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.login-douglas
  width: 100%
  height: 100%
  background-color: #fff

  .login-header
    position: relative
    width: 100%

    .close
      position: absolute
      top: 0
      right: 0
      m-icon("close", 40)

    .login-title
      m-font-size(30, 34)

  .login-body
    display: flex

    .title
      m-font-size(20, 24)
      font-weight: 600

    .middle
      flex: 1 1 50%

      &.right
        .gender-input
          display: flex
          align-items: center
</style>