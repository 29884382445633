<template>
  <label class="container">
    <div v-if="label" class="label">{{ label }}</div>
    <input type="checkbox" :checked="value" @input="$emit('change',$event.target.checked)">
    <div :class="{checkmark:true,marked:value}" />
  </label>
</template>
<script>
export default {
  props: {
    label: String,
    value: Boolean,
  },
}
</script>
<style lang="stylus" scoped>
.container
  position: relative
  display: block
  width: vw(24px)
  height: vw(24px)
  cursor: pointer

  .label
    margin-left: vw(25px)

  > input
    position: absolute
    top: 0
    left: 0
    margin: 0
    width: 100%
    height: 100%
    opacity: 0
    cursor: pointer

.checkmark,
.marked
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.checkmark
  m-icon("checkbox", 24)

.marked
  m-icon("checkbox-checked", 24)
</style>