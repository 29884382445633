<template>
  <label class="basic-radio-button">
    {{ label }}
    <input
      class="radio-btn"
      type="radio"
      :name="name"
      :value="value"
      :checked="checked"
      @click="$emit('click',$event)"
      @change="$emit('change',$event)"
      @input="$emit('input',$event)"
      @blur="$emit('blur',$event)"
      @focus="$emit('focus',$event)"
    >
    <span class="checkmark" />
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'input',
  },
  props: {
    checked: { type: Boolean, required: true },
    name: { type: String, required: true },
    value: { type: String, required: true },
    label: { type: String, required: false },
  },
}
</script>

<style lang="stylus" scoped>
.basic-radio-button
  position: relative
  display: block
  margin-bottom: 12px
  padding-left: 35px
  font-size: 22px
  cursor: pointer
  user-select: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none

  &:hover
    >>> input ~ .checkmark
      background-color: #ccc

  >>> input
    position: absolute
    width: 0
    height: 0
    opacity: 0
    cursor: pointer

    &:checked
      ~ .checkmark
        background-color: #2196F3

      ~ .checkmark:after
        display: block

  .checkmark
    position: absolute
    top: 0
    left: 0
    width: 25px
    height: 25px
    background-color: #eee

    &:after
      position: absolute
      top: 5px
      left: 9px
      display: none
      width: 5px
      height: 10px
      border: solid white
      border-width: 0 3px 3px 0
      content: ""
      transform: rotate(45deg)
      -webkit-transform: rotate(45deg)
      -ms-transform: rotate(45deg)
</style>